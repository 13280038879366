/* global document, googletag, window */

export const getParameters = (name, url = window.location.href) => {
  const regex = new RegExp(`[\\?&]${name}=([^&#]*)`);
  const results = regex.exec(url);

  return (results === null) ? null : results[1];
};

export const hasDebug = (name) => {
  const debugValue = getParameters('glmawdb');

  if (debugValue !== null) {
    return debugValue.split('|').indexOf(name) > -1;
  }

  return false;
};

export const readCookie = (cookieDebugName) => {
  const cookie = `;${document.cookie}`.match(`;\\s*${cookieDebugName}=([^;]+)`);

  return cookie !== null ? cookie[1] : false;
};

export const log = (data) => {
  if (typeof data !== 'object') {
    data = [data];
  }

  /* eslint-disable no-console */
  if (console && typeof console.log === 'function' && hasDebug('console')) {
    console.log(...[
      '%cGLMAW',
      'display: inline-block; color: #61d2a3; background: #193a4a; padding: 1px 4px; border-radius: 3px;',
      ...data,
    ]);
  }
  /* eslint-enable no-console */
};

export const throttle = (callback, wait) => {
  let time = Date.now();

  return () => {
    if (((time + wait) - Date.now()) < 0) {
      callback();
      time = Date.now();
    }
  };
};

export const extend = (initial, adds) => {
  Object.keys(adds).forEach((key) => {
    if (typeof initial[key] === 'undefined') {
      initial[key] = adds[key];
    } else if (typeof adds[key] !== typeof initial[key]) {
      log(`bad configuration type for ${key}, must be an instance of ${typeof initial[key]}`);
    } else if (typeof adds[key] === 'object') {
      extend(initial[key], adds[key]);
    } else {
      initial[key] = adds[key];
    }
  });

  return initial;
};

export const filterFormats = (initial, given, excluded) => {
  const result = {};

  Object.keys(initial).forEach((k1) => {
    if (typeof given[k1] !== 'undefined') {
      Object.keys(given[k1]).forEach((k2) => {
        initial[k1][k2] = given[k1][k2];
      });
    }

    if (typeof excluded[k1] === 'undefined') {
      result[k1] = initial[k1];
    } else {
      const subset = {};

      Object.keys(initial[k1]).forEach((k2) => {
        if (typeof excluded[k1][k2] === 'undefined') {
          subset[k2] = initial[k1][k2];
        }

        if (Array.isArray(initial[k1][k2]) && Array.isArray(excluded[k1][k2])) {
          const excls = excluded[k1][k2].map((entry) => entry.toString());
          const formats = initial[k1][k2].filter((entry) => excls.indexOf(entry.toString()) === -1);

          subset[k2] = formats;
        }
      });

      result[k1] = subset;
    }
  });

  return result;
};

const slotIds = {};
export const getNextIdBySlotFormat = (format) => {
  if (typeof slotIds[format] === 'undefined') {
    slotIds[format] = 0;
  }

  slotIds[format] += 1;

  return slotIds[format] === 1 ? format : `${format}-${slotIds[format]}`;
};

export const getSlotByElementId = (elementId) => googletag.pubads().getSlots()
  .filter((slot) => slot.getSlotElementId() === elementId)
  .shift();

export const getSlotsByLineItemId = (lineItemId) => googletag.pubads().getSlots()
  .filter((slot) => slot.lineItemId === lineItemId);

export const loadScript = (url, async = true) => new Promise((resolve, reject) => {
  // Is script already loading
  let dirty = false;

  const script = document.createElement('script');
  if (async) {
    script.async = true;
  } else {
    script.async = false; // means async-download but ordered-execution!
  }
  script.src = url;
  script.type = 'text/javascript';

  script.onload = () => {
    if (!dirty && (!script.readyState || script.readyState === 'loaded' || script.readyState === 'complete')) {
      dirty = true;
      script.onload = null;
      script.onreadystatechange = null;

      resolve(script);
    }
  };

  script.onreadystatechange = script.onload;
  script.onerror = reject;

  document.head.appendChild(script);
});
