/* global window, document, googletag, MutationObserver */
import {
  getNextIdBySlotFormat, getParameters, log, loadScript, readCookie,
} from '../lib/utils';
import configuration from '../lib/config';
import { renderAds } from './renderAds';
import { nodeComingInViewport } from '../lib/lazyload';

let nodes;

export const init = () => {
  window.googletag = window.googletag || {};
  window.googletag.cmd = window.googletag.cmd || [];

  loadScript('//securepubads.g.doubleclick.net/tag/js/gpt.js');
};

export const configure = () => new Promise((resolve) => {
  window.abp = window.abp || false;

  googletag.cmd.push(() => {
    googletag.pubads().enableSingleRequest();
    googletag.pubads().disableInitialLoad();
    googletag.pubads().collapseEmptyDivs(true, true);
    // Mark ad requests to request non-personalized ads.
    googletag.pubads().setRequestNonPersonalizedAds(0);
    // Target ad requests using AdBlock Plus detection.
    log(`set GPT page-level targeting abp = ${window.abp}`);
    googletag.pubads().setTargeting('abp', `${window.abp}`);
    googletag.pubads().setPublisherProvidedId(readCookie(configuration.ppidCookieName));
    googletag.enableServices();

    nodes = document.querySelectorAll(configuration.slots);
    let v;
    let node;

    for (v = 0; v < nodes.length; v += 1) {
      node = nodes[v];

      const format = node.getAttribute('data-format');
      const pageAdUnit = configuration.adUnit || '';
      const slotId = getNextIdBySlotFormat(format);

      let sizes = false;
      let slotAdUnit = false;

      if (
        typeof configuration.specificAdUnit[format] !== 'undefined'
        && typeof configuration.specificAdUnit[format][configuration.device] !== 'undefined'
        && configuration.specificAdUnit[format][configuration.device].length > 0
        && typeof configuration.specificAdUnit[format].tag !== 'undefined'
      ) {
        sizes = configuration.specificAdUnit[format][configuration.device];
        slotAdUnit = configuration.specificAdUnit[format].tag;
      }

      if (
        typeof configuration.formats[format] !== 'undefined'
        && typeof configuration.formats[format][configuration.device] !== 'undefined'
        && configuration.formats[format][configuration.device].length > 0
      ) {
        sizes = configuration.formats[format][configuration.device];
        slotAdUnit = `${pageAdUnit}/${format}`;
      }

      if (sizes && slotAdUnit) {
        node.setAttribute('id', slotId);

        if (sizes === 'out-of-page' || sizes === 'out') {
          googletag.defineOutOfPageSlot(slotAdUnit, slotId)
            .setTargeting('pos', format)
            .addService(googletag.pubads());
        } else {
          googletag.defineSlot(slotAdUnit, sizes, slotId)
            .setTargeting('pos', format)
            .setCollapseEmptyDiv(true)
            .addService(googletag.pubads());
        }

        // This will only register the slot. Ad will be fetched only when refresh is called.
        googletag.display(slotId);
      }
    }

    configuration.targets.testScenario = configuration.prebid.testScenario;
    configuration.targets.test = getParameters('dfptest');

    Object.keys(configuration.targets).forEach((index) => {
      if (!Array.isArray(configuration.targets[index])) {
        googletag.pubads().setTargeting(index, [`${configuration.targets[index]}`]);
      } else {
        googletag.pubads().setTargeting(index, configuration.targets[index].map((value) => `${value}`));
      }
    });

    resolve(nodes);
  });
});

const removeUnloadedClassName = () => {
  const dfpSlots = document.querySelectorAll(configuration.slots);
  const observer = new MutationObserver((mutations) => {
    mutations.forEach((mutation) => {
      if (mutation.addedNodes.length > 0 && mutation.target.className.includes('dfp-unloaded')) {
        const adsLoaded = document.getElementById(mutation.target.id);
        adsLoaded.classList.remove(configuration.unloadedClassName);
        log(`${mutation.target.id} -> remove class ${configuration.unloadedClassName} by mutation`);
      }
    });
  });
  dfpSlots.forEach((dfpSlot) => {
    dfpSlot.classList.remove(configuration.loadingClassName);
    observer.observe(dfpSlot, { childList: true, subtree: true });
  });
};

export const display = () => {
  log('display ads');
  const immediate = [];
  const lazy = [];

  const handler = () => {
    lazy.forEach((node, index) => {
      if (nodeComingInViewport(node)) {
        log(`load node ${node.getSlotElementId()}`);
        googletag.pubads().refresh([node]);
        lazy.splice(index, 1);
      }
    });

    if (lazy.length < 1) {
      document.removeEventListener('scroll', handler);
    }
  };

  googletag.pubads().getSlots().forEach((node) => {
    const element = document.querySelector(`#${node.getSlotElementId()}`);

    if (
      configuration.lazyload.excludedFormats.indexOf(element.getAttribute('data-format')) > -1
      || nodeComingInViewport(node)
      || node.getOutOfPage()
    ) {
      immediate.push(node);
    } else {
      lazy.push(node);
    }
  });

  googletag.cmd.push(() => {
    if (immediate.length > 0) {
      log(`immediate display for ${immediate.length} node(s)`);
      googletag.pubads().refresh(immediate);
    }

    log(`lazyload ${lazy.length} node(s)`);
    document.addEventListener('scroll', handler, false);
  });

  // const detectLoadedSlot = () => {
  //   const slots = document.querySelectorAll(configuration.slots);
  //   slots.forEach((slot) => {
  //     const { id } = slot;

  //     if (id.includes('inread')) {
  //       log(`hide ${id} slot`);
  //       slot.style.display = 'none';
  //     }
  //   });

  //   googletag.pubads().addEventListener('slotOnload', (event) => {
  //     const { slot } = event;
  //     log(`Creative iframe for slot ${slot.getSlotElementId()} has loaded.`);

  //     if (slot.getSlotElementId().includes('inread')) {
  //       log(`display ${slot.getSlotElementId()} slot`);
  //     }
  //   });
  // };

  // detectLoadedSlot();
  renderAds();
  removeUnloadedClassName();
};
