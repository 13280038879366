/* global googletag, window, pbjs */
import { log, getParameters } from '../lib/utils';
import configuration from '../lib/config';
import * as appNexus from '../lib/prebid/appNexus';
import * as criteo from '../lib/prebid/criteo';
import * as invibes from '../lib/prebid/invibes';
import * as smart from '../lib/prebid/smart';
import * as sublime from '../lib/prebid/sublime';
import * as teads from '../lib/prebid/teads';
import * as ix from '../lib/prebid/ix';
import * as xandr from '../lib/prebid/xandr';

const parseBidResponse = (bid) => {
  const adData = {
    isRoadblock: false,
    lineItemCode: '',
  };
  let adMatch;

  try {
    if ('mediaType' in bid && (bid.mediaType === 'video-outstream' || bid.mediaType === 'video')) {
      adMatch = bid.adResponse.ad.rtb.video.content.match(/bounce\?NXO_LINE_ITEM_CODE=([^\]<]*)/);
      if (adMatch != null) {
        [, adData.lineItemCode] = adMatch;
      }
    } else if ('mediaType' in bid && bid.mediaType === 'native') {
      const impTrackers = bid.native.impressionTrackers;
      for (let i = 0; i < impTrackers.length; i += 1) {
        if (impTrackers[i].indexOf('ib.adnxs.com/bounce?NXO_LINE_ITEM_CODE') !== -1) {
          adData.lineItemCode = impTrackers[i].substring(impTrackers[i].indexOf('CODE=') + 5);
          break;
        }
      }
    } else {
      adMatch = bid.ad.match(/\/\* NXO_START (\{.*?\}) NXO_END \*\//);
      if (adMatch != null) {
        const parsed = JSON.parse(adMatch[1]);
        Object.keys(parsed).forEach((key) => {
          adData[key] = parsed[key];
        });
      }
    }
  } catch (e) {
    log(`Failed to decode creative -> ${e}`);
  }

  return adData;
};

const getHighestBids = () => {
  // get all bids
  const allBids = pbjs.getBidResponses();
  // store here the highest bids for all placements
  const bidsOrdered = [];

  const selectHighestBid = (bids) => {
    // exclude bids that have no cpm at all
    bids = bids.filter((bid) => bid.cpm > 0);
    // sort by (adjusted) cpm, from highest to lowest
    bids.sort((a, b) => b.cpm - a.cpm);
    // return highest cpm from resulting list, or return null
    // if no bid is available
    return bids.length > 0 ? bids[0] : null;
  };

  Object.values(allBids).forEach((bid) => {
    bidsOrdered.push(selectHighestBid(bid.bids));
  });

  return bidsOrdered;
};

const setDfpTargeting = () => {
  const keywords = {};
  const bids = getHighestBids();
  let tagKeywords = {};
  let adData;
  let keyValueToPrimary = '';

  keywords[`${configuration.skyline.prefix.keyValueToPrimary}roadblock`] = 0;
  keywords[`${configuration.skyline.prefix.keyValueToPrimary}r_line_item_code`] = '';

  /* Page is using disableInitialLoad and async mode (doesn't use single request mode)
   * Assuming this means all page-level targeting must be passed on each slot individually
   * Loop over the responses first to check for any roadblocks and build the necessary targeting
   */

  bids.forEach((bid) => {
    keyValueToPrimary = '';
    if (!bid) {
      return;
    }

    if (bid.bidder === configuration.skyline.adaptor) {
      ({ keyValueToPrimary } = configuration.skyline.prefix);
    }

    if (bid.getStatusCode() === 1) {
      tagKeywords = {};
      tagKeywords.hb_partner_key = bid.bidder;

      // execute the NEXTONE specific parsing code */
      if (bid.bidder === configuration.skyline.adaptor) {
        // Extract the JSON which was included as a pixel on the creative
        adData = parseBidResponse(bid);

        // Check if a roadblock line item has served from the Nextone ad server
        if (bid.adUnitCode === configuration.skyline.roadblockSignalTag) {
          if (adData.isRoadblock) {
            keywords[`${configuration.skyline.prefix.keyValueToPrimary}roadblock`] = 1;
            keywords[`${configuration.skyline.prefix.keyValueToPrimary}r_line_item_code`] = adData.lineItemCode;
          }

          // Set page-level targeting to indicate if a roadblock line item is serving
          Object.keys(keywords).forEach((key) => {
            googletag.pubads().setTargeting(key, keywords[key].toString());
          });
        }

        // Set other slot-level targeting to describe the Nextone demand
        tagKeywords[`${configuration.skyline.prefix.keyValueToPrimary}demand_source`] = bid.buyerMemberId === configuration.skyline.member ? 'direct' : 'rtb';
        tagKeywords[`${configuration.skyline.prefix.keyValueToPrimary}creative_id`] = bid.creativeId || bid.creative_id;
        tagKeywords[`${configuration.skyline.prefix.keyValueToPrimary}line_item_code`] = adData.lineItemCode;
      }

      Object.keys(bid.adserverTargeting).forEach((key) => {
        // Add prebid targeting tag-level configuration to render the creative later
        tagKeywords[keyValueToPrimary + key] = bid.adserverTargeting[key] || '';
      });

      // Loop over the DFP ad slots to find the one which is equal to this target id
      googletag.pubads().getSlots().forEach((slot) => {
        if (slot.getAdUnitPath() === bid.adUnitCode || slot.getSlotElementId() === bid.adUnitCode) {
          // Set slot-level targeting
          Object.keys(tagKeywords).forEach((key) => {
            slot.setTargeting(key, tagKeywords[key].toString());
          });
        }
      });
    } else {
      // Loop over the DFP ad slots to find the one which is equal to this target id
      googletag.pubads().getSlots().forEach((slot) => {
        if (slot.getAdUnitPath() === bid.adUnitCode || slot.getSlotElementId() === bid.adUnitCode) {
          // clear slot-level targeting
          pbjs.bidderSettings.standard.adserverTargeting.forEach((object) => {
            slot.clearTargeting(keyValueToPrimary + object.key);
          });
        }
      });
    }
  });
};

const excludeFormatsHB = (adUnits, bidder = null) => {
  if (adUnits) {
    let formatsHB = configuration.excludedFormatsHB;
    if (bidder === 'appNexus') {
      formatsHB = configuration.headerBidding.excludedFormats;
    }

    for (let i = 0; i < formatsHB.length; i += 1) {
      adUnits = adUnits.filter((e) => e.code !== formatsHB[i]);
    }
  }
  return adUnits;
};

// Callback which performs Prebid.js header bidding.
export function performAsyncBiddingForVideo() {
  let videos;
  if (configuration.videoClassName) {
    videos = window.document.getElementsByClassName(configuration.videoClassName);
  }
  if (videos) {
    pbjs.que.push(() => {
      pbjs.addAdUnits(ix.generateAdUnits(videos));
      pbjs.requestBids({
        bidsBackHandler: () => {
          // Create a new event, allow bubbling, and provide QS params
          const event = new window.CustomEvent('prebidAdsParamsReady', {
            bubbles: true,
            detail: {
              params: () => videos && Array.from(videos).map((video, index) => getParameters('cust_params',
                pbjs.adServers.dfp.buildVideoUrl({
                  adUnit: `preroll-${index}`,
                  params: {
                    iu: `${configuration.adUnit.split('/')[1]}/preroll`,
                    output: 'vast',
                  },
                }))),
            },
          });
          window.dispatchEvent(event);
        },
      });
    });
  }
}

export const init = () => {
  window.pbjs = window.pbjs || {};
  pbjs.que = pbjs.que || [];
  pbjs.renderSkin = configuration.renderSkin;
  // import prebid lib without extra network request
  import(
    /* webpackChunkName: "prebid" */
    /* webpackMode: "eager" */
    // eslint-disable-next-line comma-dangle
    '../vendor/prebid6.10.0'
  );
};

export const doBid = (callback) => {
  pbjs.que.push(() => {
    pbjs.requestBids({
      bidsBackHandler: (bidResponses) => {
        let bidAvailables = 0;
        log('bidResponses', bidResponses);
        if (Object.keys(bidResponses).length !== 0) {
          Object.keys(bidResponses).forEach((name) => {
            if (bidResponses[name].bids && bidResponses[name].bids.length > 0) {
              bidResponses[name].bids.forEach((bid) => {
                if (bid.getStatusCode() === 1) {
                  bidAvailables += 1;
                  log(`${name} -> ${bid.bidderCode} -> ${bid.statusMessage}`);
                }
              });
            }
          });
        }

        if (bidAvailables === 0) {
          log('No bid availables');
        }

        setDfpTargeting();
        if (callback) {
          callback();
        }
      },
      timeout: configuration.prebid.timeout,
    });
  });
};

export const sendBid = () => new Promise((resolve) => {
  doBid(resolve);
});

export const configure = () => {
  pbjs.que.push(() => {
    pbjs.aliasBidder('appnexusAst', configuration.skyline.adaptor);

    pbjs.bidderSettings = {
      standard: {
        adserverTargeting: [
          {
            key: 'hb_bidder',
            val: (bidResponse) => bidResponse.bidderCode,
          }, {
            key: 'hb_adid',
            val: (bidResponse) => bidResponse.adId,
          }, {
            key: 'hb_pb',
            val: appNexus.calculateCpmBucket,
          }, {
            key: 'hb_size',
            val: (bidResponse) => bidResponse.size,
          }, {
            key: 'hb_deal_id',
            val: (bidResponse) => bidResponse.dealId,
          }, {
            key: 'hb_cache_id',
            val: (bidResponse) => bidResponse.videoCacheKey,
          }, {
            key: 'hb_uuid',
            val: (bidResponse) => bidResponse.videoCacheKey,
          },
        ],
      },
      criteo: {
        adserverTargeting: [{
          key: 'crt_pb',
          val: criteo.calculateCpmBucket,
        }],
      },
      rubicon: {
        adserverTargeting: [
          {
            key: 'hb_adid_rubicon',
            val: (bidResponse) => bidResponse.adId,
          }, {
            key: 'hb_pb_rubicon',
            val: appNexus.calculateCpmBucket,
          }, {
            key: 'hb_deal_rubicon',
            val: (bidResponse) => bidResponse.dealId,
          },
        ],
      },
    };

    const pbjsConfig = {
      consentManagement: {
        gdpr: {
          cmpApi: 'iab',
          timeout: 8000,
          allowAuctionWithoutConsent: true,
        },
      },
      bidderTimeout: 3000,
      rubicon: {
        singleRequest: true,
      },
      currency: {
        // enables currency feature
        adServerCurrency: 'EUR',
        granularityMultiplier: 1,
        // recommended in case there’s an issue loading the currency file
        defaultRates: { USD: { EUR: 0.89 } },
      },
      cache: {
        url: 'https://prebid.adnxs.com/pbc/v1/cache',
      },
      // For Smile Wanted config
      userSync: {
        iframeEnable: true,
        filterSettings: {
          iframe: {
            bidders: '*',
            filter: 'include',
          },
          image: {
            bidders: '*',
            filter: 'include',
          },
        },
        syncDelay: 3000,
        syncEnabled: true,
        syncsPerBidder: 5,
      },
    };

    pbjs.setConfig(pbjsConfig);
    pbjs.addAdUnits(excludeFormatsHB(xandr.generateAdUnits(googletag.pubads().getSlots(), 'appNexus')));
    pbjs.addAdUnits(excludeFormatsHB(criteo.generateAdUnits(googletag.pubads().getSlots())));
    pbjs.addAdUnits(excludeFormatsHB(smart.generateAdUnits(googletag.pubads().getSlots())));
    pbjs.addAdUnits(excludeFormatsHB(sublime.generateAdUnits(googletag.pubads().getSlots())));
    pbjs.addAdUnits(excludeFormatsHB(teads.generateAdUnits(googletag.pubads().getSlots())));
    pbjs.addAdUnits(excludeFormatsHB(invibes.generateAdUnits(googletag.pubads().getSlots())));
  });
};
