/* global googletag, window */
import { loadScript, log } from '../lib/utils';
import configuration from '../lib/config';

const getSizes = (slot) => {
  const sizes = slot.getSizes().map((size) => {
    if (typeof size === 'string') {
      return size;
    }
    return [size.getWidth(), size.getHeight()];
    /* eslint-disable arrow-body-style */
  }).filter((size) => {
    if (size[0] === 1800) {
      return false;
    }
    return Array.isArray(size) && (![1, 2].includes(size[0]) || ![1, 2].includes(size[1]));
  });
  return sizes;
};

/* Generate the ad units based on the page's tags */
const generateAmazonAdUnits = () => {
  const slots = googletag.pubads().getSlots().map((slot) => {
    return {
      slotID: slot.getSlotElementId(),
      slotName: slot.getAdUnitPath(),
      sizes: getSizes(slot),
    };
  }).filter((slot) => {
    return slot.sizes.length > 0;
  });
  return slots;
};

export function performAsyncBiddingForVideo() {
  const videos = window.document.getElementsByClassName('js_player');

  if (videos) {
    // Launch the bidding process
    window.apstag.fetchBids(
      {
        slots: [
          {
            slotID: 'videoSlot', // Slot name
            mediaType: 'video',
          },
        ],
      },
      (bids) => {
        if (bids.length > 0) {
          log('bids amz', bids);
          // If we have received any bids back
          const event = new window.CustomEvent('amazonAdsParamsReady', {
            bubbles: true,
            detail: {
              params: () => `${bids[0].helpers.encodedQsParams()}`,
            },
          });
          window.dispatchEvent(event);
          configuration.requestAmzVideoDone = true;
        }
      },
    );
  }
}

export const init = () => {
  if (typeof window.apstag !== 'undefined') return;
  /* eslint-disable no-underscore-dangle */
  /* eslint-disable no-undef */
  window.apstag = {
    init: (...args) => {
      window.apstag._Q.push(['i', args]);
    },
    fetchBids: (...args) => {
      window.apstag._Q.push(['f', args]);
    },
    setDisplayBids: () => { },
    _Q: [],
  };
  /* eslint-enable no-underscore-dangle */
  /* eslint-enable no-undef */
  loadScript('//c.amazon-adsystem.com/aax2/apstag.js');

  window.apstag.init({
    pubID: '3789',
    adServer: 'googletag',
    videoAdServer: 'DFP',
    deals: true,
  });

  log('Amazon HB initiated via apstag.init()');
};

export const doBid = (callback) => {
  const apstagSlots = generateAmazonAdUnits();
  window.apstag.fetchBids({
    slots: apstagSlots,
    timeout: configuration.prebid.timeout, // take the same timeout value as prebid
  }, (bids) => {
    log(`Amazon bids returned -> ${JSON.stringify(bids)}`);
    googletag.cmd.push(() => {
      window.apstag.setDisplayBids();
      if (callback) {
        callback();
      }
    });
  });
  log(`Amazon bid request fired for -> ${JSON.stringify(apstagSlots)}`);
};

export const sendBid = () => new Promise((resolve) => {
  doBid(resolve);
});
