/* global window, googletag */

import { loadScript, log } from '../lib/utils';
import configuration from '../lib/config';

export const load = () => new Promise((resolve) => {
  if (configuration.indexWrapper) {
    log(`load ix library for ${configuration.site}`);
    loadScript(configuration.indexWrapper).then(resolve);
  } else {
    log(`no ix library for ${configuration.site}`);
    resolve();
  }
});

export const sendBid = () => {
  const slotsList = [];
  window.headertag = window.headertag || {};
  window.headertag.cmd = window.headertag.cmd || [];

  googletag.cmd.push(() => {
    googletag.pubads().getSlots().forEach((slot) => {
      slotsList.push({ slot });
    });
  });

  window.headertag.cmd.push(() => {
    window.headertag.retrieveAndSetDemand(slotsList, (error) => {
      if (error) {
        log(error);
      } else {
        log('ix bid demand sent');
      }
    });
  });
};
