import configuration from '../config';

const getSizes = (name) => {
  if (typeof configuration.formats[name] === 'undefined') {
    return [];
  }

  const overridedSizes = {
    inread: {
      desktop: [
        [533, 300],
      ],
      tablet: [
        [300, 250],
      ],
      mobile: [
        [300, 250],
      ],
    },
  };

  if (overridedSizes[name] && overridedSizes[name][configuration.device]) {
    return overridedSizes[name][configuration.device];
  }

  return [];
};

export const generateAdUnits = (slots) => {
  const adUnits = [];

  /**
  * Ad units for the prebid ad call are built the usual way for prebid.js
  * DFP adUnitPath is converted into a Nextone invCode by replacing / with - and adding a prefix
  */
  slots.forEach((slot) => {
    /**
    * used to know if at least one HB partner should be added.
    * If false, then don't add an ad unit. If true, add the ad Unit
    */
    let addAdUnit = false;

    const name = slot.getAdUnitPath().split('/').slice(-1)[0];
    const adUnit = {
      code: slot.getSlotElementId(),
      pubstack: {
        adUnitName: name,
        adUnitPath: slot.getAdUnitPath(),
      },
      sizes: getSizes(name),
      bids: [],
    };

    if (name === 'inread') {
      adUnit.mediaTypes = {
        video: {
          context: 'outstream',
        },
        banner: {
          sizes: getSizes(name),
        },
      };
    } else {
      adUnit.mediaTypes = {
        banner: {
          sizes: getSizes(name),
        },
      };
    }

    if (typeof configuration.teads[configuration.site] !== 'undefined'
      && configuration.teads.formats.indexOf(name) > -1
      && adUnit.sizes.length > 0) {
      adUnit.bids.push({
        bidder: configuration.teads.adaptor,
        params: {
          pageId: configuration.teads[configuration.site].pageId,
          placementId: configuration.teads[configuration.site].placementId,
        },
      });
      addAdUnit = true;
    }

    if (addAdUnit) {
      adUnits.push(adUnit);
    }
  });

  return adUnits;
};
