/* global Adomik, googletag, window */

const randomAdGroup = () => {
  const rand = Math.random();

  switch (false) {
    case !(rand < 0.09):
      return `ad_ex${Math.floor(100 * rand)}`;
    case !(rand < 0.10):
      return 'ad_bc';
    default:
      return 'ad_opt';
  }
};

export const configure = () => {
  googletag.pubads().getSlots().forEach((slot) => {
    slot
      .setTargeting('ad_group', randomAdGroup())
      .setTargeting('ad_h', new Date().getUTCHours().toString());
  });
};

window.Adomik = window.Adomik || {};
Adomik.randomAdGroup = randomAdGroup;
