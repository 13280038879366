import configuration from '../config';

export const getSizes = (name) => {
  if (typeof configuration.formats[name] === 'undefined') {
    return [];
  }

  const sizes = configuration.formats[name][configuration.device];
  const additionalSizes = {
    banniere_haute: {
      desktop: [[1800, 1000]],
    },
    inread: {
      desktop: [[300, 600]],
    },
  };
  const overridedSizes = {
    habillage: {
      desktop: [[1800, 1000]],
    },
    cover: {
      desktop: [
        [1, 1],
      ],
      tablet: [
        [1, 1],
      ],
      mobile: [
        [1, 1],
      ],
    },
  };

  if (overridedSizes[name] && overridedSizes[name][configuration.device]) {
    return overridedSizes[name][configuration.device];
  }

  if (sizes === 'out-of-page') {
    return ['out-of-page'];
  }

  const result = sizes.filter((size) => size !== 'fluid');

  if (additionalSizes[name] && additionalSizes[name][configuration.device]) {
    return result.concat(additionalSizes[name][configuration.device]);
  }

  return result;
};

export const calculateCpmBucket = (bidResponse) => {
  let cpmStr = '';

  const { cpm } = bidResponse;
  const granularityMultiplier = 1;
  const buckets = (bidResponse.adUnitCode in configuration.prebid.granularities)
    ? configuration.prebid.granularities[bidResponse.adUnitCode]
    : configuration.prebid.granularities.default;

  const cap = buckets.reduce((prev, curr) => {
    if (prev.max > curr.max) {
      return prev;
    }

    return curr;
  }, {
    max: 0,
  });

  const bucket = buckets.find((item) => {
    if (cpm >= cap.max * granularityMultiplier) {
      const precision = item.precision || configuration.prebid.defaultPrecision;
      cpmStr = (item.max * granularityMultiplier).toFixed(precision);
    } else if (cpm < item.max * granularityMultiplier && cpm >= item.min * granularityMultiplier) {
      return item;
    }

    return false;
  });

  if (bucket) {
    const bucketSize = 1 / (bucket.increment * granularityMultiplier);
    const precision = bucket.precision || configuration.prebid.defaultPrecision;
    cpmStr = ((Math.floor(cpm * bucketSize) / bucketSize) + bucket.increment).toFixed(precision);
  }

  return cpmStr;
};

export const generateAdUnits = (slots) => {
  const adUnits = [];
  const nxoKeywords = {};
  const lmhbKeywords = {};

  nxoKeywords['test-nxo_scenario'] = configuration.prebid.testScenario;
  lmhbKeywords['test-lmhb_scenario'] = configuration.prebid.testScenario;

  /**
     * Include the page's own key values (e.g. "artid") with a prefix so they
     * can be distinguished in the Nextone ad server (e.g. "nxo_lemonde_artid")
     */
  Object.keys(configuration.targets).forEach((idx) => {
    nxoKeywords[configuration.skyline.prefix.keyValueToPrebid + idx] = configuration.targets[idx];
    lmhbKeywords[idx] = configuration.targets[idx];
  });

  /**
     * Ad units for the prebid ad call are built the usual way for prebid.js
     * DFP adUnitPath is converted into a Nextone invCode by replacing / with - and adding a prefix
     */
  slots.forEach((slot) => {
    /**
       * used to know if at least one HB partner should be added.
       * If false, then don't add an ad unit. If true, add the ad Unit
       */
    let addAdUnit = false;

    const name = slot.getAdUnitPath().split('/').slice(-1)[0];
    const adUnit = {
      code: slot.getSlotElementId(),
      pubstack: {
        adUnitName: name,
        adUnitPath: slot.getAdUnitPath(),
      },
      sizes: getSizes(name),
      bids: [],
    };

    if (name === 'inread') {
      adUnit.mediaTypes = {
        video: {
          context: 'outstream',
        },
        banner: {
          sizes: getSizes(name),
        },
      };
    } else {
      adUnit.mediaTypes = {
        banner: {
          sizes: getSizes(name),
        },
      };
    }

    if (configuration.skyline.formats.indexOf(name) > -1) {
      adUnit.bids.push({
        bidder: configuration.skyline.adaptor,
        params: {
          member: configuration.skyline.member,
          invCode: configuration.skyline.prefix.adUnitPath + slot.getAdUnitPath().replace(/\//g, configuration.skyline.prefix.adUnitSeparator),
          keywords: nxoKeywords,
        },
      });

      addAdUnit = true;
    }

    if (configuration.headerBidding.excludedFormats.indexOf(name) === -1) {
      const tmp = slot.getAdUnitPath().split('/');
      tmp.splice(3, 1);

      adUnit.bids.push({
        bidder: configuration.headerBidding.adaptor,
        params: {
          member: configuration.headerBidding.member,
          invCode: configuration.headerBidding.prefix.adUnitPath
            + tmp.join(configuration.headerBidding.prefix.adUnitSeparator),
          keywords: lmhbKeywords,
        },
      });
    }

    if (typeof configuration.rubicon[configuration.site] !== 'undefined'
      && typeof configuration.rubicon[configuration.site]
        .placements[name] !== 'undefined') {
      const zoneId = configuration.rubicon[configuration.site]
        .placements[name].tag;

      adUnit.bids.push({
        bidder: configuration.rubicon.adaptor,
        params: {
          accountId: configuration.rubicon.accountId,
          siteId: configuration.rubicon[configuration.site].siteId,
          zoneId,
        },
      });

      addAdUnit = true;
    }

    if (addAdUnit) {
      adUnits.push(adUnit);
    }
  });

  return adUnits;
};
