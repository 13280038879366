/* global STAGING_BUILD */
import { loadScript, log } from './lib/utils';
import { isStaging } from './modules/debug';

import * as dfp from './modules/dfp';
import * as amzn from './modules/amazon';
import * as prebid from './modules/prebid';
import * as refresh from './modules/refresh';
import * as ixlibrary from './modules/ixlibrary';
import * as adomik from './modules/adomik';
import * as pubstack from './modules/pubstack';
import * as grapeshot from './modules/grapeshot';

const execute = () => {
  pubstack.init();
  ixlibrary.load();
  prebid.init();
  // prebid.performAsyncBiddingForVideo();
  dfp.init();
  amzn.init();
  amzn.performAsyncBiddingForVideo();

  dfp.configure().then(() => {
    grapeshot.load().then(() => {
      prebid.configure();
      const timeout = new Promise((resolve) => setTimeout(resolve, 3000));
      const parallelBid = [prebid, amzn, ixlibrary].map((platform) => platform.sendBid());
      Promise.race([Promise.all(parallelBid), timeout]).then(() => {
        adomik.configure();
        dfp.display();
        refresh.init();
      });
    });
  });
};

const init = () => {
  if (isStaging('glmaw_staging') === true && STAGING_BUILD === false) {
    log('Load staging wrapper script');
    loadScript('https://wrapper.lemde.fr/glmaw-staging.js');
  } else {
    log('init wrapper');
    execute();
  }
};

export {
  init,
};
