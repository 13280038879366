import configuration from '../config';
import { hasDebug } from '../utils';

export const getPublisherSubId = (adUnitPath) => adUnitPath.split('/').splice(2).join('_');

const getSizes = (name) => {
  if (configuration
    && configuration.criteo
    && configuration.criteo.formats
    && configuration.criteo.formats[name] !== undefined
  ) {
    return configuration.criteo.formats[name][configuration.device];
  }

  return [];
};

export const calculateCpmBucket = (bidResponse) => {
  if (!bidResponse.cpm) {
    return null;
  }

  if (hasDebug('force-criteo')) {
    return Math.floor(Math.random() * (200 - 100 + 1) + 100);
  }

  const { cpm } = bidResponse;

  if (cpm >= 0.01 && cpm < 9.00) {
    return (Math.floor(cpm * 100) / 100).toFixed(2);
  }

  if (cpm >= 9.00 && cpm < 18.00) {
    return (Math.floor(cpm * 25) / 25).toFixed(2);
  }

  if (cpm >= 18.00 && cpm < 40.00) {
    return (Math.floor(cpm * 10) / 10).toFixed(2);
  }

  if (cpm >= 40.00) {
    return 40.00;
  }

  return null;
};

export const generateAdUnits = (slots) => {
  const adUnits = [];

  slots.forEach((slot) => {
    const name = slot.getAdUnitPath().split('/').slice(-1)[0];

    const adUnit = {
      code: slot.getSlotElementId(),
      pubstack: {
        adUnitName: name,
        adUnitPath: slot.getAdUnitPath(),
      },
      bids: [],
    };

    if (configuration.criteo.formats[name] !== undefined) {
      adUnit.sizes = getSizes(name);

      if (name === 'inread') {
        adUnit.mediaTypes = {
          video: {
            context: 'outstream',
            playerSize: [640, 480],
            mimes: ['video/mp4'],
            maxduration: 30,
            api: [1, 2],
            protocols: [2, 3],
            skip: 1,
            playbackmethod: [6],
            placement: 3,
          },
          banner: {
            sizes: adUnit.sizes,
          },
        };
      } else {
        adUnit.mediaTypes = {
          banner: {
            sizes: adUnit.sizes,
          },
        };
      }
      adUnit.bids.push({
        bidder: 'criteo',
        params: {
          networkId: configuration.criteo.networkId,
          publisherSubId: getPublisherSubId(slot.getAdUnitPath()),
        },
      });
    }

    if (adUnit.bids.length > 0) {
      adUnits.push(adUnit);
    }
  });

  return adUnits;
};
