/* global document, pbjs */
import { log } from '../lib/utils';
import configuration from '../lib/config';

// Customize ads render depending on winning bidder
export const renderAds = () => {
  const currentSite = configuration.site;
  const banniereHaute = document.getElementById('banniere_haute');

  pbjs.onEvent('bidWon', (data) => {
    log(`${data.bidderCode} won the ad server auction for ad unit ${data.adUnitCode} at ${data.cpm} CPM`);

    switch (currentSite) {
      case 'lemonde':
        if (data.bidderCode === 'rubicon' && data.adUnitCode === 'banniere_haute') {
          log(`custom render for ${data.bidderCode} on ${data.adUnitCode}`);
          const zoneArticle = document.querySelector('#habillagepub .zone--article');
          banniereHaute.style.height = '210px';
          banniereHaute.style.margin = '0px';
          zoneArticle.style.position = 'relative';
          zoneArticle.style.background = 'white';
        }

        if (data.bidderCode === 'sublime' && data.adUnitCode === 'banniere_haute' && data.width === 1800) {
          log(`custom render for ${data.bidderCode} on ${data.adUnitCode}`);
          banniereHaute.style.marginTop = '-58px';
        }

        if (data.bidderCode === 'smart' && data.adUnitCode === 'banniere_haute' && data.width === 1800) {
          log(`custom render for ${data.bidderCode} on ${data.adUnitCode}`);
          banniereHaute.style.height = '220px';
          banniereHaute.style.margin = '0px';
        }
        break;
      case 'huffpost':
        if (data.bidderCode === 'smart' && data.adUnitCode === 'banniere_haute') {
          log(`custom render for ${data.bidderCode} on ${data.adUnitCode}`);
          const leaderBoard = document.querySelector('.leaderboard-flex-placeholder');
          const advertisement = document.querySelector('.advertisement');
          const buzzHead = document.querySelector('.js-cet-unit-buzz_head');
          const buzzBody = document.querySelector('.js-cet-unit-buzz_body');

          banniereHaute.style.height = '220px';
          banniereHaute.style.margin = '0px';
          leaderBoard.style.position = 'absolute';
          advertisement.style.background = 'white';
          buzzHead.style.top = '220px';
          buzzHead.style.maxWidth = '1200px';
          buzzHead.style.margin = 'auto';
          buzzBody.style.position = 'relative';
          buzzBody.style.top = '150px';
        }
        break;
      case 'courrier':
        if (data.bidderCode === 'smart' && data.adUnitCode === 'banniere_haute' && data.width === 1800) {
          log(`custom render for ${data.bidderCode} on ${data.adUnitCode}`);
          banniereHaute.style.height = '220px';
          banniereHaute.style.margin = '0px';
        }
        break;
      case 'lobs':
        if (data.bidderCode === 'rubicon' && data.adUnitCode === 'banniere_haute' && data.width === 1800) {
          log(`custom render for ${data.bidderCode} on ${data.adUnitCode}`);
          const hasSocialShare = document.querySelector('#habillagepub .hasSocialShare');
          banniereHaute.style.height = '220px';
          hasSocialShare.style.padding = '10px';
        }
        if (data.bidderCode === 'appnexus' && data.adUnitCode === 'banniere_haute' && data.width === 1800) {
          log(`custom render for ${data.bidderCode} on ${data.adUnitCode}`);
          banniereHaute.style.height = '220px';
        }
        break;
      default:
    }
  });
};
