export default {
  pubstack: '//boot.pbstck.com/v1/tag/464148e4-900d-4b00-a21b-4f9d8704c57f',
  smartConsentlessData: 'https://storage.googleapis.com/prd-lemonde-io-wrapper/mapping/smart-consentless-conjugation-en.json',
  smartAdCallConfig: {},
  excludedFormatsHB: ['inimage_seed'],
  // Sublime
  sublime: {
    theconjugation: {
      formats: {
        banniere_haute: { zoneId: 31892 },
        cover: { zoneId: 31891 },
      },
    },
  },
  // Teads
  teads: {
    theconjugation: { pageId: 117398, placementId: 127456 },
  },
  // Smart
  smart: {
    theconjugation: {
      siteId: 278833,
      pageId: 1247546,
    },
  },
  // Rubicon
  rubicon: {
    theconjugation: {
      siteId: 335970,
      placements: {
        banniere_haute: { tag: 1768588 },
        banniere_basse: { tag: 1768586 },
        pave_haut: { tag: 1768590 },
        pave_milieu: { tag: 1768592 },
      },
    },
  },
  indexWrapper: '//js-sec.indexww.com/ht/p/189918-120339113691628.js',
  invibes: {
    placementId: 'invibes_nouvelobs',
  },
};
