import configuration from '../config';
import { log } from '../utils';

export const generateAdUnits = (videos) => {
  const videoAdUnits = [];

  for (let i = 0; i < videos.length; i += 1) {
    videoAdUnits.push({
      code: `preroll-${i}`,
      mediaTypes: {
        video: {
          playerSize: [
            // Dimensions might not be final while player is setting up.
            640,
            480,
          ],
          context: 'instream',
        },
      },
      bids: [
        {
          bidder: 'ix',
          params: configuration.instreamIndex,
        },
      ],
    });
  }
  log('VideoAdUnits', videoAdUnits);
  return videoAdUnits;
};
