import configuration from '../config';
import { getSizes } from './appNexus';

export const generateAdUnits = (slots) => {
  const adUnits = [];

  /**
  * Ad units for the prebid ad call are built the usual way for prebid.js
  * DFP adUnitPath is converted into a Nextone invCode by replacing / with - and adding a prefix
  */
  slots.forEach((slot) => {
    /**
    * used to know if at least one HB partner should be added.
    * If false, then don't add an ad unit. If true, add the ad Unit
    */
    let addAdUnit = false;

    const name = slot.getAdUnitPath().split('/').slice(-1)[0];
    const adUnit = {
      code: slot.getSlotElementId(),
      pubstack: {
        adUnitName: name,
        adUnitPath: slot.getAdUnitPath(),
      },
      sizes: getSizes(name),
      bids: [],
    };

    adUnit.mediaTypes = {
      banner: {
        sizes: getSizes(name),
      },
    };

    if (typeof configuration.smart.formats[name] !== 'undefined'
        && typeof configuration.smart[configuration.site] !== 'undefined') {
      const { pageId } = configuration.smart[configuration.site];

      adUnit.bids.push({
        bidder: configuration.smart.adaptor,
        params: {
          siteId: configuration.smart[configuration.site].siteId,
          pageId,
          formatId: configuration.smart.formats[name].formatId,
        },
      });

      addAdUnit = true;
    }

    if (addAdUnit) {
      adUnits.push(adUnit);
    }
  });

  return adUnits;
};
