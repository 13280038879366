/* global window */
/* eslint-disable no-mixed-operators */
import configuration from '../config';
import { loadScript } from '../utils';

window.sublime = window.sublime || {};
window.sublime.notifyId = (function b(a) {
  // eslint-disable-next-line no-bitwise
  return a ? (a ^ Math.random() * 16 >> a / 4).toString(16) : ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, b);
}());

const getSizes = (name) => {
  if (typeof configuration.formats[name] === 'undefined') {
    return [];
  }

  const overridedSizes = {
    banniere_haute: {
      desktop: [[1800, 1000]],
    },
    cover: {
      mobile: [[1, 1]],
    },
  };

  if (overridedSizes[name] && overridedSizes[name][configuration.device]) {
    return overridedSizes[name][configuration.device];
  }

  return [];
};

export const generateAdUnits = (slots) => {
  const adUnits = [];

  /**
  * Ad units for the prebid ad call are built the usual way for prebid.js
  * DFP adUnitPath is converted into a Nextone invCode by replacing / with - and adding a prefix
  */
  slots.forEach((slot) => {
    /**
    * used to know if at least one HB partner should be added.
    * If false, then don't add an ad unit. If true, add the ad Unit
    */
    let addAdUnit = false;

    const name = slot.getAdUnitPath().split('/').slice(-1)[0];
    const adUnit = {
      code: slot.getSlotElementId(),
      pubstack: {
        adUnitName: name,
        adUnitPath: slot.getAdUnitPath(),
      },
      sizes: getSizes(name),
      bids: [],
    };

    adUnit.mediaTypes = {
      banner: {
        sizes: getSizes(name),
      },
    };
    if (typeof configuration.sublime[configuration.site] !== 'undefined'
      && typeof configuration.sublime[configuration.site].formats[name] !== 'undefined'
      && Object.keys(configuration.sublime[configuration.site].formats).indexOf(name) > -1
      && adUnit.sizes.length > 0) {
      adUnit.bids.push({
        bidder: configuration.sublime.adaptor,
        params: {
          zoneId: configuration.sublime[configuration.site].formats[name].zoneId,
          notifyId: window.sublime.notifyId,
        },
      });
      addAdUnit = true;

      // Adding Sublime SAC tag
      loadScript(`https://sac.ayads.co/sublime/${configuration.sublime[configuration.site].formats[name].zoneId}/prebid`);
    }

    if (addAdUnit) {
      adUnits.push(adUnit);
    }
  });

  return adUnits;
};
