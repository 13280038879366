/* global window, document, googletag, MouseEvent */
import configuration from '../lib/config';
import Poller from '../lib/poller';
import {
  log, throttle, hasDebug, getSlotsByLineItemId,
} from '../lib/utils';
import { sendBid as refreshAmazon } from './amazon';
import { doBid as refreshPrebid } from './prebid';

let scrolledAt = Date.now();
const lineItemHistory = [];

const tick = (slot) => {
  const now = Date.now();
  const delta = {
    sinceLastActived: now - (slot.lastActivedAt || 0),
    sinceLastScroll: now - scrolledAt,
    sinceRendered: now - slot.renderedAt,
    sinceViewed: now - slot.viewedAt,
  };

  // for debug
  slot.scrolledAt = scrolledAt;

  if (delta.sinceRendered > configuration.refresh.minDisplayTime
    && delta.sinceViewed > configuration.refresh.minSinceViewed
    && delta.sinceLastScroll < configuration.refresh.maxInactivity
    && (slot.active === false || slot.active === undefined)
    && delta.sinceLastActived > configuration.refresh.minSinceActive
    && slot.refreshing === false) {
    let toRefresh = [slot];

    if (configuration.refresh.respectRoadblocks === true && slot.lineItemId !== null) {
      toRefresh = getSlotsByLineItemId(slot.lineItemId);
    }

    log(`refresh slot ${slot.getSlotElementId()}`);
    slot.refreshing = true;

    refreshAmazon().then(() => {
      refreshPrebid(() => {
        const element = document.querySelector(`#${slot.getSlotElementId()}`);
        element.classList.add(configuration.unloadedClassName);
        googletag.pubads().refresh(toRefresh);
      });
    });

    toRefresh.forEach((s) => {
      s.refreshed = (s.refreshed || 0) + 1;
    });

    if (lineItemHistory.length > 0) {
      googletag.pubads().setTargeting('lih', lineItemHistory);
    }
  }
};

const poller = new Poller(tick, configuration.refresh.tick);

const isSlotForbiddenFromRefreshing = (slot) => configuration.refresh.excludedFormats
  .filter((name) => slot.getAdUnitPath().indexOf(name) > -1)
  .length > 0;

const isSlotAvailableForRefresh = (slot) => {
  // Slot is one of those we don't want to refresh

  if (
    isSlotForbiddenFromRefreshing(slot) || (
      slot.lineItemId !== undefined
      && slot.lineItemId !== null
      && getSlotsByLineItemId(slot.lineItemId).filter(isSlotForbiddenFromRefreshing).length > 0
    )
  ) {
    return false;
  }

  return true;
};

const onSlotRenderEnded = (event) => {
  event.slot.lineItemId = event.lineItemId;
  event.slot.renderedAt = Date.now();
  event.slot.refreshing = false;

  if (lineItemHistory.indexOf(event.lineItemId) === -1) {
    lineItemHistory.push(event.lineItemId);
  }
};

const onSlotVisibilityChanged = (event) => {
  event.slot.inViewPercentage = event.inViewPercentage;

  if (isSlotAvailableForRefresh(event.slot) === false) {
    return;
  }

  const onMouseOver = () => {
    event.slot.active = true;
    event.slot.lastActivedAt = 0;
  };

  const onMouseOut = () => {
    event.slot.active = false;
    event.slot.lastActivedAt = Date.now();
  };

  if (event.inViewPercentage > configuration.refresh.inViewPercentage) {
    if (poller.has(event.slot.getSlotElementId()) === false) {
      event.slot.viewedAt = Date.now();

      poller.register(event.slot.getSlotElementId(), event.slot);
      document.getElementById(event.slot.getSlotElementId()).addEventListener('mouseover', onMouseOver);
      document.getElementById(event.slot.getSlotElementId()).addEventListener('mouseout', onMouseOut);
    }
  } else if (poller.has(event.slot.getSlotElementId()) === true) {
    event.slot.viewedAt = 0;

    poller.unregister(event.slot.getSlotElementId());
    document.getElementById(event.slot.getSlotElementId()).removeEventListener('mouseover', onMouseOver);
    document.getElementById(event.slot.getSlotElementId()).removeEventListener('mouseout', onMouseOut);
  }
};

export const init = () => {
  log('init refresh');

  googletag.cmd.push(() => {
    window.addEventListener('scroll', throttle(() => {
      scrolledAt = Date.now();
    }, 1000));
    googletag.pubads().addEventListener('slotRenderEnded', onSlotRenderEnded);
    googletag.pubads().addEventListener('slotVisibilityChanged', onSlotVisibilityChanged);
  });

  if (hasDebug('refresh')) {
    googletag.cmd.push(() => {
      const toSeconds = (ms) => `${ms / 1000}s`;
      const toOverlayId = (slotElementId) => `${slotElementId}_lmd_refresh_overlay`;

      const draw = (slot, overlay) => {
        let html = `<strong>${slot.seid}</strong>`;
        const now = Date.now();

        html += `<p>Line Item ID: <strong>${slot.lineItemId}</strong></p>`;

        if (isSlotAvailableForRefresh(slot)) {
          html += `<p>Rendered since: <strong>${toSeconds(slot.renderedAt ? now - slot.renderedAt : 0)}</strong></p>`;
          html += `<p>Viewed since: <strong>${toSeconds(slot.viewedAt ? now - slot.viewedAt : 0)}</strong></p>`;
          html += `<p>In view percentage: <strong>${(slot.inViewPercentage || 0)}</strong></p>`;
          html += `<p>Active: <strong>${(slot.active === true ? 'YES' : 'NO')}</strong></p>`;
          html += `<p>Last actived since: <strong>${toSeconds(slot.lastActivedAt ? now - slot.lastActivedAt : 0)}</strong></p>`;
          html += `<p>User inactive since: <strong>${toSeconds(slot.scrolledAt ? now - slot.scrolledAt : 0)}</strong></p>`;
          html += `<p>Number of refresh: <strong>${(slot.refreshed || 0)}</strong></p>`;
        } else {
          html += '<p>Slot forbidden from refreshing</p>';
        }

        overlay.innerHTML = html;
      };

      const positionOverlay = (overlay, slotElement) => {
        const adsElement = slotElement.children[0] || slotElement;
        const rect = adsElement.getBoundingClientRect();

        overlay.style.width = `${rect.width}px`;
        overlay.style.height = `${rect.height}px`;
        overlay.style.top = `${rect.top + window.pageYOffset}px`;
        overlay.style.left = `${rect.left + window.pageXOffset}px`;
      };

      const createOverlay = (slotElement) => {
        const overlay = document.createElement('div');

        overlay.id = toOverlayId(slotElement.id);

        overlay.style.opacity = 0.75;
        overlay.style.position = 'absolute';
        overlay.style.border = '1px solid #e4e6e9';
        overlay.style.paddingLeft = '11px';
        overlay.style.paddingTop = '11px';
        overlay.style.background = 'peachpuff';
        overlay.style.overflow = 'hidden';
        overlay.style.zIndex = 2147483645;
        overlay.style.boxSizing = 'border-box';
        overlay.style.lineHeight = 0.5;

        overlay.addEventListener('mouseover', () => {
          slotElement.dispatchEvent(new MouseEvent('mouseover'));
        });
        overlay.addEventListener('mouseout', () => {
          slotElement.dispatchEvent(new MouseEvent('mouseout'));
        });

        document.body.appendChild(overlay);

        return overlay;
      };

      googletag.pubads().addEventListener('slotOnload', (event) => {
        const element = document.getElementById(event.slot.seid);
        const overlay = document.getElementById(toOverlayId(element.id)) || createOverlay(element);

        if (event.slot.empty === true) {
          overlay.parentNode.removeChild(overlay);
        } else {
          positionOverlay(overlay, element);
          draw(event.slot, overlay);
        }
      });

      googletag.pubads().addEventListener('slotRenderEnded', (event) => {
        event.slot.seid = event.slot.getSlotElementId();
        event.slot.empty = event.isEmpty === true || (event.size && event.size.toString() === '1,1');
      });

      setInterval(() => {
        googletag.pubads().getSlots().forEach((slot) => {
          const overlay = document.getElementById(toOverlayId(slot.getSlotElementId()));
          if (!overlay) {
            return;
          }

          draw(slot, overlay);
        });
      }, 1000);
    });
  }
};
