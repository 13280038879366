export default {
  pubstack: '//boot.pbstck.com/v1/tag/dfdca06d-b195-4b0b-94dc-4af4fe251bce',
  indexWrapper: '//js-sec.indexww.com/ht/p/189918-126916174508452.js',
  smartConsentlessData: 'https://storage.googleapis.com/prd-lemonde-io-wrapper/mapping/smart-consentless-courrier.json',
  smartAdCallConfig: {},
  excludedFormatsHB: ['inimage_seed'],
  formats: {
    inread: {
      desktop: [
        'fluid',
        [2, 2],
        [300, 250],
        [490, 276],
      ],
      tablet: [
        'fluid',
        [2, 2],
        [300, 250],
      ],
      mobile: [
        'fluid',
        [2, 2],
        [300, 250],
      ],
    },
  },
  invibes: {
    placementId: 'invibes_courrierinternational',
  },
};
