/* global document, googletag, window */
import { loadScript, log } from '../lib/utils';

export const load = () => new Promise((resolve) => {
  const url = window.location !== window.top.location ? document.referrer : window.location;

  window.gs_channels = 'DEFAULT';

  log('grapeshot script load');

  loadScript(`//le-monde-ams.gscontxt.net/main/channels.cgi?url=${encodeURIComponent(url)}`).then(() => {
    googletag.cmd.push(() => {
      log(`grapeshot targeting set ${window.gs_channels}`);

      googletag.pubads().setTargeting('gs_cat', window.gs_channels);

      resolve();
    });
  });
});
