/* eslint-disable no-case-declarations */
/* eslint-disable no-return-assign */
/* global ADS_CONFIG, document, performance, window */

import { site as detectSite, device as deviceDetect } from '../detect';
// eslint-disable-next-line import/no-cycle
import { filterFormats, extend, getParameters } from '../utils';
import SiteFactory from './sites';

const currentSite = detectSite(SiteFactory().getSites());

const configuration = {
  // site
  site: currentSite,
  // device
  device: deviceDetect(),
  // Ads Selector
  slots: '.dfp-slot',
  unloadedClassName: 'dfp-unloaded',
  loadingClassName: 'dfp-loading',
  // users
  ppidCookieName: 'lead',
  // Default formats
  formats: {
    cover: {
      desktop: 'out-of-page',
      tablet: 'out-of-page',
      mobile: 'out-of-page',
    },
    banniere_basse: {
      desktop: [
        [468, 60],
        [728, 90],
        [1000, 90],
      ],
      tablet: [
        [468, 60],
        [728, 90],
        [1000, 90],
      ],
      mobile: [
        [300, 50],
        [320, 50],
        [320, 100],
      ],
    },
    banniere_milieu: {
      desktop: [
        [468, 60],
        [728, 90],
        [970, 250],
        [970, 90],
        [1000, 90],
        [1000, 200],
        [1000, 300],
      ],
      tablet: [
        [468, 60],
        [728, 90],
        [970, 250],
        [970, 90],
        [1000, 90],
        [1000, 200],
        [1000, 300],
      ],
      mobile: [
        [300, 50],
        [320, 50],
        [320, 100],
      ],
    },
    banniere_haute: {
      desktop: [
        [468, 60],
        [728, 90],
        [970, 250],
        [970, 90],
        [1000, 90],
        [1000, 200],
        [1000, 300],
        [1800, 1000],
      ],
      tablet: [
        [468, 60],
        [728, 90],
        [970, 250],
        [970, 90],
        [1000, 90],
        [1000, 200],
        [1000, 300],
      ],
      mobile: [
        [300, 50],
        [320, 50],
        [320, 100],
      ],
    },
    parallaxe: {
      desktop: 'out-of-page',
      tablet: 'out-of-page',
    },
    pave_bas: {
      desktop: [
        [300, 250],
      ],
      tablet: [
        [300, 250],
      ],
      mobile: [
        [300, 250],
      ],
    },
    pave_haut: {
      desktop: [
        [160, 600],
        [300, 250],
        [300, 600],
      ],
      tablet: [
        [160, 600],
        [300, 250],
        [300, 600],
      ],
      mobile: [
        [300, 250],
      ],
    },
    pave_milieu: {
      desktop: [
        [160, 600],
        [300, 250],
        [300, 600],
      ],
      tablet: [
        [160, 600],
        [300, 250],
        [300, 600],
      ],
      mobile: [
        [300, 250],
      ],
    },
    inread: {
      desktop: [
        'fluid',
        [2, 2],
        [300, 250],
        [533, 300],
      ],
      tablet: [
        'fluid',
        [2, 2],
        [300, 250],
      ],
      mobile: [
        'fluid',
        [2, 2],
        [300, 250],
      ],
    },
    native_multiplex: {
      desktop: [
        'fluid',
      ],
      tablet: [
        'fluid',
      ],
      mobile: [
        'fluid',
      ],
    },
    native_multiplex_coldroite: {
      desktop: [
        'fluid',
      ],
      tablet: [
        'fluid',
      ],
      mobile: [
        'fluid',
      ],
    },
  },
  // excludedFormats
  excludedFormats: {},
  excludedFormatsHB: [],
  // specificAdUnit
  specificAdUnit: {},
  targets: {
    nt: (() => {
      if (
        typeof performance !== 'undefined'
        && typeof performance.navigation !== 'undefined'
        && typeof performance.navigation.type !== 'undefined'
      ) {
        return performance.navigation.type;
      }

      if (document.referrer === window.location.href) {
        return 1;
      }

      return 0;
    })(),
  },
  // For performance reasons, the slots will be loaded with this format order.
  // Unlisted slots will be positioned last.
  priority: [
    'cover',
    'banniere_haute',
    'pave_haut',
    'habillage',
    'pave_milieu',
    'pave_bas',
    'parallaxe',
    'inread',
    'banniere_milieu',
    'banniere_basse',
  ],
  // Lazyload
  lazyload: {
    threshold: 150,
    excludedFormats: [
      'cover',
      'pave_haut',
      'banniere_haute',
    ],
  },
  refresh: {
    inViewPercentage: 50,
    maxInactivity: 90000,
    minDisplayTime: 30000,
    minSinceActive: 5000,
    minSinceViewed: 5000,
    respectRoadblocks: true,
    tick: 500,
    excludedFormats: [],
  },
  renderSkin: (() => {
    const banner = document.getElementById('banniere_haute');
    const skin = document.getElementById('habillagepub');
    const mmPage = document.querySelector('body .mm-page');
    switch (currentSite) {
      case 'lemonde':
        banner.removeAttribute('class');
        banner.style.cssText = 'position: absolute; z-index: 0; margin: -220px auto 0; left: 50%; transform: translateX(-50%); display: flex; justify-content: center; overflow: hidden;';
        skin.style.cssText = 'position: relative; z-index: 1; margin: 220px auto; max-width: 1000px;';
        break;
      case 'courrier':
        banner.style.cssText = 'position: absolute; z-index: 0; margin: -220px auto 0; left: 50%; transform: translateX(-50%); overflow: hidden;';
        skin.style.cssText = 'position: relative; z-index: 1; margin: 220px auto;';
        // hotfix for cheaty skin
        if (mmPage) {
          mmPage.style.zIndex = 10000;
        }
        break;
      case 'telerama':
        const teleramaBanner = document.querySelector('#habillagepub #banniere_haute');
        // remove publicite grey zone underneath banner
        document.styleSheets[0].addRule('.dfp-slot.dfp-loaded::after', 'display:none !important;');
        banner.removeAttribute('class');
        if (teleramaBanner === null) { // in case banner div is placed outside habillagepub div
          banner.style.cssText = 'position: absolute; z-index: 0; margin:auto; left: 50%; transform: translateX(-50%);';
        } else {
          banner.style.cssText = 'position: absolute; z-index: -1; margin: -220px auto; left: 50%; transform: translateX(-50%);';
        }
        skin.style.cssText = 'position: relative;z-index: 1;margin: 220px auto;max-width: 1000px;';
        break;
      case 'lobs':
        const containerLobs = document.querySelector('.container.ObsRubrique');
        if (containerLobs !== null) {
          containerLobs.style.cssText = 'max-width: 1000px; padding: 15px;';
        }
        banner.classList.remove('dfp-unloaded');
        banner.classList.remove('container-auto');
        banner.style.cssText = 'position: absolute; z-index: 0; margin: auto; left: 50%; transform: translateX(-50%); display: flex; justify-content: center; max-width: none !important;';
        skin.style.cssText = 'position: relative; z-index: 1; margin: 220px auto; width: 1000px;';
        break;
      case 'huffpost':
        const huffHomePage = document.querySelector('.body-wrap');
        const huffPageWrapper = document.querySelector('.page-wrapper');
        const huffSection = document.querySelector('.section-name');
        const huffSplash = document.querySelector('.body-splash');
        const huffLife = document.querySelector('.life');

        if (huffHomePage !== null && huffLife == null) { // for home page
          const frontPageContent = document.querySelectorAll('.front-page-content');
          const frontPageTop = document.querySelector('.front-page-top');
          const mastHead = document.querySelector('.masthead');
          const subNav = document.querySelector('.subnav-container');
          const advertisement = document.querySelector('.advertisement.ad-leaderboard-flex');
          advertisement.style.cssText = 'position: absolute; z-index: -999; top: 0; margin: -220px auto 0; overflow: hidden; padding-top: 0px !important; padding-bottom: 0px !important; background-color: #fff;';
          frontPageContent.forEach((el) => el.style.cssText = 'position: relative; z-index: 1; margin: auto; max-width: 1000px !important; background-color: #fff; padding: 0px 15px;');
          frontPageTop.style.cssText = 'position: relative; z-index: 1; margin: auto; max-width: 1000px !important; background-color: #fff; padding: 0px 15px;';
          mastHead.style.cssText = 'max-width: 1000px; margin: 220px auto 0; z-index: 1; background-color: #fff;';
          subNav.style.cssText = 'display: flex; justify-content: center; max-width: 1000px; margin: auto; overflow: hidden; background-color: #fff;';
        } else if (huffPageWrapper !== null && huffSection !== null) { // for categories page
          const desktopHeader = document.getElementById('desktop__main_header');
          const innerContainer = document.querySelector('.inner-container.row');
          const sectionName = document.querySelector('.section-name');
          const pageContent = document.querySelector('.page__content__row');
          const masterContainer = document.querySelector('.master-container');
          desktopHeader.style.cssText = 'position: relative; z-index: 2; display: flex; justify-content: center; max-width: 1000px; margin: 220px auto 0; overflow: hidden;';
          innerContainer.style.cssText = 'padding-bottom: 0px; padding-top: 1px; width: 1000px; margin: auto; background-color: #fff';
          banner.style.cssText = 'position: absolute; z-index: -999; top: 0; margin: -220px auto 0; overflow: hidden; transform: translateX(-50%);';
          sectionName.style.cssText = 'position: relative; z-index: 1; margin: 0 auto 215px; overflow: hidden; padding-top: 55px;';
          pageContent.parentElement.style.cssText = 'position: relative; z-index: 1; margin: -200px auto 0; max-width: 970px !important;';
          masterContainer.style.cssText = 'z-index: 0';
        } else if (huffPageWrapper !== null && huffSplash !== null) { // for article page
          const desktopHeader = document.getElementById('desktop__main_header');
          const innerContainer = document.querySelector('.inner-container.row');
          const pageContent = document.querySelector('.page__content');
          const masterContainer = document.querySelector('.master-container');
          desktopHeader.style.cssText = 'position: relative; z-index: 2; display: flex; justify-content: center; max-width: 1000px; margin: 220px auto 0; overflow: hidden;';
          innerContainer.style.cssText = 'padding-bottom: 0px; padding-top: 1px; width: 1000px; margin: auto; background-color: #fff';
          banner.style.cssText = 'position: absolute; z-index: -999; top: 0; margin: -220px auto 0; overflow: hidden; transform: translateX(-50%);';
          pageContent.style.cssText = 'max-width: 970px !important';
          masterContainer.style.cssText = 'z-index: 0';
        } else { // for life page
          banner.style.cssText = 'display: none;';
        }
        break;
      default:
    }
  }),
  prebid: {
    defaultPrecision: 2,
    // Granularity by format
    granularities: {
      default: [
        {
          precision: 2,
          min: 0,
          max: 5,
          increment: 0.01,
        }, {
          precision: 2,
          min: 5,
          max: 20,
          increment: 0.05,
        }, {
          precision: 2,
          min: 20,
          max: 70,
          increment: 0.5,
        },
      ],
    },
    // prebid test parameter
    testScenario: getParameters('testScenario') || '',
    // prebid timeout
    timeout: 3000,
  },
  // Invibes
  invibes: {
    // The prebid alias adaptor to use
    adaptor: 'invibes',
    formats: [
      'inread',
    ],
    endpoint: 'https://bid5.videostep.com/Bid/VideoAdContent',
  },
  // Rubicon
  rubicon: {
    // The Rubicon account id
    accountId: 19358,
    // The prebid alias adaptor to use
    adaptor: 'rubicon',
    // Only send positions on the page which are sold in Rubicon
    lemonde: {
      siteId: 222330,
      placements: {
        banniere_haute: { tag: 1093384 },
        banniere_milieu: { tag: 1675456 },
        banniere_basse: { tag: 1093388 },
        pave_haut: { tag: 1093392 },
        pave_milieu: { tag: 1093394 },
        pave_bas: { tag: 1093396 },
        inread: { tag: 1093398 },
      },
    },
    huffpost: {
      siteId: 242258,
      placements: {
        banniere_haute: { tag: 1197154 },
        banniere_milieu: { tag: 1948584 },
        banniere_basse: { tag: 1197164 },
        pave_haut: { tag: 1197166 },
        pave_milieu: { tag: 1197170 },
        pave_bas: { tag: 1197172 },
        inread: { tag: 1505246 },
      },
    },
    courrier: {
      siteId: 241802,
      placements: {
        banniere_haute: { tag: 1193322 },
        banniere_milieu: { tag: 1675462 },
        banniere_basse: { tag: 1193328 },
        pave_haut: { tag: 1193330 },
        pave_milieu: { tag: 1193334 },
        pave_bas: { tag: 1193338 },
        inread: { tag: 1193378 },
      },
    },
    telerama: {
      siteId: 242294,
      placements: {
        banniere_haute: { tag: 1195536 },
        banniere_milieu: { tag: 1948572 },
        banniere_basse: { tag: 1195538 },
        pave_haut: { tag: 1195684 },
        pave_milieu: { tag: 1195700 },
        pave_bas: { tag: 1195704 },
        inread: { tag: 1505270 },
      },
    },
    lobs: {
      siteId: 235082,
      placements: {
        banniere_haute: { tag: 1158884 },
        banniere_basse: { tag: 1158886 },
        pave_haut: { tag: 1158888 },
        pave_milieu: { tag: 1158890 },
        pave_bas: { tag: 1158892 },
        inread: { tag: 1505298 },
      },
    },
    conjugacao: {
      siteId: 337462,
      placements: {
        banniere_haute: { tag: 1778190 },
        banniere_basse: { tag: 1778188 },
        pave_haut: { tag: 1778196 },
        pave_milieu: { tag: 1778200 },
      },
    },
    coniugazione: {
      siteId: 337478,
      placements: {
        banniere_haute: { tag: 1778284 },
        banniere_basse: { tag: 1778278 },
        pave_haut: { tag: 1778342 },
        pave_milieu: { tag: 1778356 },
      },
    },
    conjugacion: {
      siteId: 337488,
      placements: {
        banniere_haute: { tag: 1778448 },
        banniere_basse: { tag: 1778438 },
        pave_haut: { tag: 1778456 },
        pave_milieu: { tag: 1778462 },
      },
    },
  },
  // Skyline
  skyline: {
    // The Nextone AppNexus member id
    member: 8253,
    // The prebid alias adaptor to use
    adaptor: 'nxo',
    // Only send positions on the page which are sold in Nextone
    formats: [
      'banniere_haute',
      'pave_haut',
      'inread',
      'cover',
    ],
    prefix: {
      // To be added to the primary ad server's code
      adUnitPath: 'nxo',
      // DFP ad unit paths contain / but AppNexus placement codes cannot use this
      // character. Define which character it should be replaced with
      adUnitSeparator: '-',
      // To be added to the key values which are sent to the Nextone ad server,
      // to ensure that Le Monde's and Figaro's key values can be distinguished
      keyValueToPrebid: 'nxo_lm_',
      // To be added to the key values which are sent to the primary ad server
      // to represent the demand from Nextone
      keyValueToPrimary: 'nxo_',
    },
    // When a roadblock serves from the Nextone ad server it needs to include a
    // pixel which signals that it is a roadblock - by default we expect the pixel
    // to be included on the creative which serves on the dfp-banniere-haute placement
    roadblockSignalTag: 'banniere_haute',
  },
  // Smart
  smart: {
    // The prebid alias adaptor to use
    adaptor: 'smart',
    formats: {
      banniere_haute: { formatId: 70664 },
      banniere_milieu: { formatId: 90457 },
      banniere_basse: { formatId: 70665 },
      pave_haut: { formatId: 70666 },
      pave_milieu: { formatId: 70667 },
      pave_bas: { formatId: 70668 },
      inread: { formatId: 70670 },
    },
    // Only send positions on the page which are sold in Smart
    lemonde: {
      siteId: 268240,
      pageId: 1187330,
    },
    huffpost: {
      siteId: 278830,
      pageId: 1187328,
    },
    courrier: {
      siteId: 278827,
      pageId: 1187317,
    },
    telerama: {
      siteId: 278834,
      pageId: 1187336,
    },
    lobs: {
      siteId: 278833,
      pageId: 1187333,
    },
  },
  // Sublime
  sublime: {
    // The prebid alias adaptor to use
    adaptor: 'sublime',
    lemonde: {
      formats: {
        banniere_haute: { zoneId: 31387 },
        cover: { zoneId: 33903 },
      },
    },
    huffpost: {
      formats: {
        banniere_haute: { zoneId: 31291 },
        cover: { zoneId: 33648 },
      },
    },
    courrier: {
      formats: {
        banniere_haute: { zoneId: 31292 },
        cover: { zoneId: 33649 },
      },
    },
    telerama: {
      formats: {
        banniere_haute: { zoneId: 31293 },
        cover: { zoneId: 33650 },
      },
    },
    lobs: {
      formats: {
        banniere_haute: { zoneId: 31294 },
        cover: { zoneId: 33651 },
      },
    },
  },
  // Teads
  teads: {
    // The prebid alias adaptor to use
    adaptor: 'teads',
    formats: [
      'inread',
    ],
    lemonde: { pageId: 116058, placementId: 125950 },
    courrier: { pageId: 114220, placementId: 124063 },
    lobs: { pageId: 114221, placementId: 124064 },
    huffpost: { pageId: 114222, placementId: 124065 },
    telerama: { pageId: 114223, placementId: 124066 },
  },
  xandr: {
    member: 8499,
    adaptor: 'appnexus',
    excludedFormats: [
      'cover',
      'parallaxe',
      'habillage',
      'inimage_seed',
    ],
    lemonde: {
      publisherId: 1131427,
      banniere_haute: { placementId: 21250115 },
      banniere_milieu: { placementId: 21250126 },
      banniere_basse: { placementId: 21250107 },
      pave_haut: { placementId: 21250130 },
      pave_milieu: { placementId: 21250131 },
      pave_bas: { placementId: 21250132 },
      inread: { placementId: 21250134 },
    },
    huffpost: {
      publisherId: 1131426,
      banniere_basse: { placementId: 21249730 },
      banniere_haute: { placementId: 21249737 },
      banniere_milieu: { placementId: 21249742 },
      pave_haut: { placementId: 21249751 },
      pave_milieu: { placementId: 21249755 },
      pave_bas: { placementId: 21249760 },
      native_col_droite: { placementId: 21249776 },
      native_feed: { placementId: 21249796 },
      native_feed_bis: { placementId: 21249799 },
      inread: { placementId: 21249811 },
    },
    courrier: {
      publisherId: 1131425,
      banniere_basse: { placementId: 21249654 },
      banniere_haute: { placementId: 21249655 },
      pave_haut: { placementId: 21249656 },
      banniere_milieu: { placementId: 21249657 },
      pave_milieu: { placementId: 21249659 },
      pave_bas: { placementId: 21249660 },
      inread: { placementId: 21249691 },
    },
    telerama: {
      publisherId: 1131430,
      banniere_basse: { placementId: 21250161 },
      banniere_haute: { placementId: 21250164 },
      pave_haut: { placementId: 21250167 },
      banniere_milieu: { placementId: 21250166 },
      pave_milieu: { placementId: 21250168 },
      pave_bas: { placementId: 21250170 },
      inread: { placementId: 21250173 },
    },
    lobs: {
      publisherId: 1131429,
      banniere_basse: { placementId: 21875747 },
      banniere_haute: { placementId: 21875741 },
      pave_haut: { placementId: 21875748 },
      pave_milieu: { placementId: 21875749 },
      pave_bas: { placementId: 21875750 },
      inread: { placementId: 21875751 },
    },
    conjugaison: {
      publisherId: 1131429,
      banniere_basse: { placementId: 12392743 },
      banniere_haute: { placementId: 12392744 },
      pave_haut: { placementId: 12392748 },
      pave_milieu: { placementId: 12392749 },
      inread: { placementId: 12392753 },
    },
    theconjugation: {
      publisherId: 1131429,
      banniere_basse: { placementId: 20388089 },
      banniere_haute: { placementId: 19370283 },
      pave_haut: { placementId: 19370298 },
      pave_milieu: { placementId: 19370323 },
      inread: { placementId: 19370319 },
    },
    konjugation: {
      publisherId: 1131429,
      banniere_basse: { placementId: 18440928 },
      banniere_haute: { placementId: 18440892 },
      pave_haut: { placementId: 18440908 },
      pave_milieu: { placementId: 20354470 },
      inread: { placementId: 18440922 },
    },
    conjugacao: {
      publisherId: 1131429,
      banniere_basse: { placementId: 20388079 },
      banniere_haute: { placementId: 19613623 },
      pave_haut: { placementId: 19613624 },
      pave_milieu: { placementId: 19613626 },
      inread: { placementId: 19613625 },
    },
    coniugazione: {
      publisherId: 1131429,
      banniere_basse: { placementId: 19613642 },
      banniere_haute: { placementId: 19613638 },
      pave_haut: { placementId: 19613639 },
      pave_milieu: { placementId: 19613641 },
      inread: { placementId: 19613640 },
    },
    conjugacion: {
      publisherId: 1131429,
      banniere_basse: { placementId: 20388084 },
      banniere_haute: { placementId: 19613649 },
      pave_haut: { placementId: 19613650 },
      pave_milieu: { placementId: 19613652 },
      inread: { placementId: 19613651 },
    },

  },
  headerBidding: {
    // The Nextone AppNexus member id
    member: 8499,
    // The prebid alias adaptor to use
    adaptor: 'lmhb',
    // Do not send positions on the page which are not sold in LM Appnexus
    excludedFormats: [
      'cover',
      'parallaxe',
      'habillage',
      'inimage_seed',
    ],
    prefix: {
      // To be added to the primary ad server's code
      adUnitPath: 'lmhb',
      // DFP ad unit paths contain / but AppNexus placement codes cannot use this character.
      // Define which character it should be replaced with
      adUnitSeparator: '_',
      // To be added to the key values which are sent to the Nextone ad server,
      // to ensure that Le Monde's and Figaro's key values can be distinguished
      keyValueToPrebid: '',
      // To be added to the key values which are sent to the primary ad server
      // to represent the demand from Nextone
      keyValueToPrimary: 'lmhb_',
    },
  },
  criteo: {
    networkId: 8230,
    formats: {
      banniere_basse: {
        desktop: [
          [1000, 300],
          [1000, 200],
          [1000, 90],
          [970, 250],
          [728, 90],
          [468, 60],
        ],
        tablet: [
          [1000, 300],
          [1000, 200],
          [1000, 90],
          [970, 250],
          [728, 90],
          [468, 60],
        ],
        mobile: [
          [300, 50],
        ],
      },
      banniere_haute: {
        desktop: [
          [1000, 300],
          [1000, 200],
          [1000, 90],
          [970, 250],
          [728, 90],
          [468, 60],
        ],
        tablet: [
          [1000, 300],
          [1000, 200],
          [1000, 90],
          [970, 250],
          [728, 90],
          [468, 60],
        ],
        mobile: [
          [300, 50],
        ],
      },
      banniere_milieu: {
        desktop: [
          [468, 60],
          [728, 90],
          [970, 250],
          [970, 90],
          [1000, 90],
          [1000, 200],
          [1000, 300],
        ],
        tablet: [
          [468, 60],
          [728, 90],
          [970, 250],
          [970, 90],
          [1000, 90],
          [1000, 200],
          [1000, 300],
        ],
        mobile: [
          [300, 50],
          [320, 50],
          [320, 100],
        ],
      },
      inread: {
        desktop: [
          [533, 300],
        ],
        tablet: [
          [300, 250],
        ],
        mobile: [
          [300, 250],
        ],
      },
      pave_haut: {
        desktop: [
          [300, 250],
          [300, 600],
        ],
        tablet: [
          [300, 250],
          [300, 600],
        ],
        mobile: [
          [300, 250],
        ],
      },
      pave_milieu: {
        desktop: [
          [300, 250],
          [300, 600],
        ],
        tablet: [
          [300, 250],
          [300, 600],
        ],
        mobile: [
          [300, 250],
        ],
      },
      pave_bas: {
        desktop: [
          [300, 250],
          [300, 600],
        ],
        tablet: [
          [300, 250],
          [300, 600],
        ],
        mobile: [
          [300, 250],
        ],
      },
    },
  },
  pubstack: '',
  indexWrapper: '',
  smartConsentlessScript: '//ced.sascdn.com/tag/3937/smart.js',
  smartConsentlessData: '', // url from our gcp
  smartAdCallConfig: {},
  requestAmzVideoDone: false,
};

if (typeof ADS_CONFIG !== 'undefined') {
  // merge with given configuration in ADS_CONFIG
  extend(configuration, ADS_CONFIG);

  // diff between formats & excludedFormats
  configuration.formats = filterFormats(
    configuration.formats,
    ADS_CONFIG.formats || {},
    configuration.excludedFormats || {},
  );
}

// merge with specific config for site if exist
configuration.formats = filterFormats(
  configuration.formats,
  SiteFactory().getSpecificConfig(currentSite).formats || {},
  {},
);
extend(configuration, SiteFactory().getSpecificConfig(currentSite));

export default configuration;
