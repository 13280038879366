/* global document, window */
import configuration from './config';

export const nodeComingInViewport = (node) => {
  const element = document.querySelector(`#${node.getSlotElementId()}`);

  if (!element) {
    return false;
  }

  const elementPosition = element.getBoundingClientRect().top;
  const threshold = window.innerHeight + configuration.lazyload.threshold;

  // If the element is above the threshold, then it is "about to come into view"
  return elementPosition < threshold;
};
