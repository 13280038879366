function Poller(callback, intervalDelay) {
  this.registered = [];
  this.interval = null;
  this.delay = intervalDelay;
  this.callback = callback;
  this.started = false;
}

Poller.prototype.start = function start() {
  this.interval = setInterval(this.execute.bind(this), this.delay);

  this.started = true;
};

Poller.prototype.stop = function stop() {
  clearInterval(this.interval);
  this.started = false;
};

Poller.prototype.execute = function execute() {
  this.registered.map((pair) => this.callback(pair.value));
};

Poller.prototype.register = function register(key, value) {
  this.registered.push({ key, value });

  if (this.started === false) {
    this.start();
  }
};

Poller.prototype.has = function has(key) {
  return this.registered.filter((pair) => pair.key === key).length !== 0;
};

Poller.prototype.unregister = function unregister(key) {
  this.registered = this.registered.filter((pair) => key !== pair.key);

  if (this.registered.length === 0) {
    this.stop();
  }
};

export default Poller;
